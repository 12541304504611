import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { isInObject } from '../../../../helper/UtilityFunctions';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { selectPrescriptionInfo, setPrescriptionInfo } from '../../../../store/reducers';
import type { ReducerAction } from '../../../../types/navigationIndex.types';
import { Button, Headline, RadioButton } from '../../../atoms';
import { ProgressStep, ProgressSteps } from '../../../organisms/ProgressSteps/ProgressSteps';
import classes from './SelfAssessment.module.scss';

const cx = classNames.bind(classes);

type SelfAssessmentProps = {
  setCurrentPage: React.Dispatch<ReducerAction>;
};

export function SelfAssessment({ setCurrentPage }: SelfAssessmentProps) {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const prescriptionInfo = useAppSelector(selectPrescriptionInfo);
  const [prescriptionTemp, setPrescriptionTemp] = useState(prescriptionInfo);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const error = !isInObject(prescriptionTemp, 'isSafeHandling') || !isInObject(prescriptionTemp, 'roadTraffic');
    if (error) {
      setIsButtonDisabled(true);
    } else {
      setIsButtonDisabled(false);
    }
  }, [prescriptionTemp]);

  const handleBackButton = () => {
    const reducerType = {
      type: '[UPDATE]',
      payload: {
        followUpPrescriptionsIndex: 3,
      },
    };
    setCurrentPage(reducerType);
  };

  const handleIsSafeHandlingChange = (value: boolean) => {
    setPrescriptionTemp({
      ...prescriptionTemp,
      isSafeHandling: value,
    });
  };

  const handleRoadTrafficChange = (value: string) => {
    setPrescriptionTemp({
      ...prescriptionTemp,
      roadTraffic: value,
    });
  };

  const next = () => {
    dispatch(setPrescriptionInfo(prescriptionTemp));

    const reducerType = {
      type: '[UPDATE]',
      payload: {
        followUpPrescriptionsIndex: 5,
      },
    };
    setCurrentPage(reducerType);
  };

  return (
    <div className={cx('SelfAssessment')}>
      <ProgressSteps>
        <ProgressStep step={1} title='Fragebogen' active />
        <ProgressStep step={2} title='Apotheke' />
        <ProgressStep step={3} title='Lieferbarkeit' />
        <ProgressStep step={4} title='Bestätigung' />
        <ProgressStep step={5} title='Zahlung' />
      </ProgressSteps>
      <Headline level={3}>Selbsteinschätzung</Headline>
      <div className={cx('safe-handling')}>
        <div className={cx('question')}>
          Ich fühle mich im Umgang und in der Anwendung meiner aktuellen Medikation sicher. Eine Videosprechstunde mit
          meinem behandelnden Arzt ist derzeit nicht notwendig.
        </div>
        <div className={cx('radio-buttons')}>
          <RadioButton
            extraClasses={cx('radio-button')}
            checked={prescriptionTemp.isSafeHandling === true}
            value='yes'
            name='is-safe-handling'
            id='is-safe-handling-yes'
            onClick={() => handleIsSafeHandlingChange(true)}
          >
            Ja
          </RadioButton>
          <RadioButton
            extraClasses={cx('radio-button')}
            checked={prescriptionTemp.isSafeHandling === false}
            value='no'
            name='is-safe-handling'
            id='is-safe-handling-no'
            onClick={() => handleIsSafeHandlingChange(false)}
          >
            Nein
          </RadioButton>
        </div>
      </div>
      <div className={cx('road-traffic')}>
        <div className={cx('question')}>
          Ich fühle mich mit meiner aktuellen Medikation sicher im Rahmen der Selbsteinschätzung (Selbstevaluation) am
          Straßenverkehr teilzunehmen.
        </div>
        <div className={cx('radio-buttons')}>
          <RadioButton
            extraClasses={cx('radio-button')}
            checked={prescriptionTemp.roadTraffic === 'yes'}
            value='yes'
            name='road-traffic'
            id='road-traffic-yes'
            onClick={() => handleRoadTrafficChange('yes')}
          >
            Ja
          </RadioButton>
          <RadioButton
            extraClasses={cx('radio-button')}
            checked={prescriptionTemp.roadTraffic === 'no'}
            value='no'
            name='road-traffic'
            id='road-traffic-no'
            onClick={() => handleRoadTrafficChange('no')}
          >
            Nein
          </RadioButton>
        </div>
      </div>
      <div className={cx('button-container')}>
        <Button secondary onClick={handleBackButton}>
          Zurück
        </Button>
        <Button disabled={isButtonDisabled} onClick={next}>
          Weiter
        </Button>
      </div>
    </div>
  );
}
