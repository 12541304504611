/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import UserService from '../../../../services/User.service';
import type { ReducerAction } from '../../../../types/navigationIndex.types';
import type { DoctorSlot } from '../../../../types/user.types';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Button, InputField } from '../../../atoms';
import InputLoader from '../../../atoms/InputLoader/InputLoader';
import { ContractCheckbox } from '../../../organisms/ContractCheckbox/ContractCheckbox';
import { ProgressStep, ProgressSteps } from '../../../organisms/ProgressSteps/ProgressSteps';
import type { AssignedDoctor } from '../OnlineAppointment';
import classes from './StepThreeOnlineAppointment.module.scss';

const cx = classNames.bind(classes);

type StepThreeOnlineAppointmentProps = {
  setCurrentPage: React.Dispatch<ReducerAction>;
  isSaving: boolean;
  selectedSlot?: DoctorSlot;
  bookAppointment: () => Promise<void>;
  assignedDoctor?: AssignedDoctor;
  setAssignedDoctor: React.Dispatch<React.SetStateAction<AssignedDoctor | undefined>>;
  couponCode: string;
  setCouponCode: React.Dispatch<React.SetStateAction<string>>;
};

const stepsOnlineAppointmentClasses = cx({ StepThreeOnlineAppointment: true, StepsContainer: true });
const infoTextClasses = cx({ InfoText: true });
const appointmentInfoContainerClasses = cx({ AppointmentInfoContainer: true });
const appointmentInfoLabelClasses = cx({ AppointmentInfoLabel: true });
const appointmentInfoDescriptionClasses = cx({ AppointmentInfoDescription: true });
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const couponCodeFieldClasses = cx({ CouponCodeField: true });
const buttonContainerClasses = cx({ ButtonContainer: true });
const backButtonClasses = cx({ BackButton: true });

export function StepThreeOnlineAppointment({
  setCurrentPage,
  isSaving,
  selectedSlot,
  bookAppointment,
  assignedDoctor,
  setAssignedDoctor,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  couponCode,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setCouponCode,
}: StepThreeOnlineAppointmentProps) {
  const [contractAccepted, setContractAccepted] = useState(false);

  useEffect(() => {
    if (!selectedSlot) {
      return;
    }

    UserService.getAllDoctors().then((res) => {
      if (res.error) {
        console.error(res.message);

        return;
      }
      const allDoctors = res.data;
      const slotDoctorId = parseInt(selectedSlot.doctorId, 10);

      const matchingDoctor = allDoctors.find((doctor: any) => doctor.id === slotDoctorId);

      if (!matchingDoctor) {
        console.error('Doctor from slot not found');

        return;
      }

      const newAssignedDoctor: AssignedDoctor = {
        doctorId: slotDoctorId,
        doctor: matchingDoctor,
      };

      setAssignedDoctor(newAssignedDoctor);
    });
  }, [selectedSlot]);

  let appointmentTime = null;

  if (selectedSlot) {
    appointmentTime = `${new Date(selectedSlot.start).toLocaleString('de-DE', {
      day: '2-digit',
      month: 'long',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    })} Uhr`;
  }

  let doctorStamp = '';

  if (assignedDoctor && assignedDoctor.doctor) {
    doctorStamp = (assignedDoctor.doctor as any).stamp;
  }

  return (
    <div className={stepsOnlineAppointmentClasses}>
      <ProgressSteps>
        <ProgressStep step={1} title='Wähle ein Datum' active />
        <ProgressStep step={2} title='Wähle eine Uhrzeit' active />
        <ProgressStep step={3} title='Bestätigung' active />
        <ProgressStep step={4} title='Zahlung' />
      </ProgressSteps>
      <div className={infoTextClasses}>Termin unten bestätigen.</div>
      <div className={appointmentInfoContainerClasses}>
        <div className={appointmentInfoLabelClasses}>Termin</div>
        <div className={appointmentInfoDescriptionClasses}>Videosprechstunde</div>
      </div>
      <div className={appointmentInfoContainerClasses}>
        <div className={appointmentInfoLabelClasses}>Datum / Uhrzeit</div>
        <div className={appointmentInfoDescriptionClasses}>{selectedSlot ? appointmentTime : ' '}</div>
      </div>
      <div className={appointmentInfoContainerClasses}>
        <div className={appointmentInfoLabelClasses}>Arzt / Ärztin</div>
        <div className={appointmentInfoDescriptionClasses}>
          {' '}
          {assignedDoctor && assignedDoctor?.doctor.title
            ? assignedDoctor?.doctor.title === 'doctor'
              ? 'Dr.'
              : assignedDoctor?.doctor.title === 'professor'
                ? 'Prof.'
                : ''
            : ''}
          {assignedDoctor && assignedDoctor?.doctor.firstname} {assignedDoctor && assignedDoctor?.doctor.lastname}
        </div>
      </div>
      {/* <div className={appointmentInfoContainerClasses}>
        <div className={appointmentInfoLabelClasses}>
          <b>Gutscheincode</b>
        </div>
        <div className={appointmentInfoLabelClasses}>
          <div className={couponCodeFieldClasses}>
            <span>Falls Sie einen Gutscheincode haben, können Sie diesen hier eingeben:</span>
            <InputField onChange={setCouponCode} value={couponCode} />
          </div>
        </div>
      </div> */}
      <ContractCheckbox checked={contractAccepted} setChecked={setContractAccepted} doctorStamp={doctorStamp} />
      <div className={buttonContainerClasses}>
        <div className={appointmentInfoContainerClasses}>
          Klicken Sie hier, um mit der Bezahlung fortzufahren. Ihr Termin wird bestätigt, sobald die Zahlung
          abgeschlossen wurde. Bei Fragen oder Problemen <a href='/contact'>kommen Sie gern auf uns zu</a>.
        </div>
        <Button disabled={isSaving || !assignedDoctor || !contractAccepted} onClick={() => bookAppointment()}>
          {isSaving ? <InputLoader /> : <div className={classes.text}>Zur Zahlung fortfahren...</div>}
        </Button>
        <a
          className={backButtonClasses}
          onClick={() => {
            const reducerType = {
              type: '[UPDATE]',
              payload: {
                onlineAppointmentIndex: 2,
              },
            };
            setCurrentPage(reducerType);
          }}
        >
          Zurück
        </a>
      </div>
    </div>
  );
}
