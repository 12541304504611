import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import _ from 'lodash';
import { isInObject } from '../../../../helper/UtilityFunctions';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { selectPrescriptionInfo, selectUserInformation, setPrescriptionInfo } from '../../../../store/reducers';
import type { ReducerAction } from '../../../../types/navigationIndex.types';
import type { PreparationListItem } from '../../../../types/ninox.types';
import { Button, Headline, RadioButton } from '../../../atoms';
import { ProgressStep, ProgressSteps } from '../../../organisms/ProgressSteps/ProgressSteps';
import classes from './Availability.module.scss';

const cx = classNames.bind(classes);

type AvailabilityProps = {
  setCurrentPage: React.Dispatch<ReducerAction>;
};

export function Availability({ setCurrentPage }: AvailabilityProps) {
  const prescriptionInfo = useAppSelector(selectPrescriptionInfo);
  const userInformation = useAppSelector(selectUserInformation);
  const [prescriptionTemp, setPrescriptionTemp] = useState(prescriptionInfo);
  const dispatch = useAppDispatch();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [lastMedicationPreparations, setLastMedicationPreparations] = useState<PreparationListItem[]>([]);

  useEffect(() => {
    const areAllFieldsFalseOrUndefined = !prescriptionTemp?.medicationAvailable;
    setIsButtonDisabled(areAllFieldsFalseOrUndefined);
    if (userInformation && userInformation.medication && userInformation.medication.length > 0) {
      const lastMedications = userInformation.medication[userInformation.medication.length - 1].preparationListItem;
      setLastMedicationPreparations(lastMedications);

      const updatedPrescriptionTemp = {
        ...prescriptionTemp,
        preparationQuestion: lastMedications,
      };
      setPrescriptionTemp(updatedPrescriptionTemp);

      dispatch(setPrescriptionInfo(updatedPrescriptionTemp));
    } else {
      setLastMedicationPreparations([]);
    }
  }, [prescriptionTemp, userInformation]);

  const handleBackButton = () => {
    const reducerType = {
      type: '[UPDATE]',
      payload: {
        followUpPrescriptionsIndex: 6,
      },
    };
    setCurrentPage(reducerType);
  };
  const preparePreparationQuestionArrayForNextStepReplaceMedicine = () => {
    const newPrepQuestionArray = _.cloneDeep(prescriptionTemp.preparationQuestion);
    const fieldsAlreadyExist = newPrepQuestionArray.some(
      (item: any) => isInObject(item, 'changeThisPreparation') && isInObject(item, 'exchangeWithThisPreparation')
    );
    if (fieldsAlreadyExist) {
      return;
    }
    prescriptionTemp.preparationQuestion.forEach((item: any, index: number) => {
      newPrepQuestionArray[index].changeThisPreparation = false;
      newPrepQuestionArray[index].exchangeWithThisPreparation = '';
    });
    dispatch(
      setPrescriptionInfo({
        ...prescriptionTemp,
        preparationQuestion: newPrepQuestionArray,
      })
    );
  };

  const deleteNextStepReplaceMedicineFields = () => {
    const newPreparationQuestionArray: any = [];
    prescriptionTemp?.preparationQuestion?.forEach((item: any) => {
      const newObj = _.cloneDeep(item);
      delete newObj.changeThisPreparation;
      delete newObj.exchangeWithThisPreparation;
      newPreparationQuestionArray.push(newObj);
    });
    dispatch(
      setPrescriptionInfo({
        ...prescriptionTemp,
        preparationQuestion: newPreparationQuestionArray,
      })
    );
  };

  const next = () => {
    const changeMed = prescriptionTemp?.medicationAvailable === 'changeMedication';

    if (changeMed) {
      preparePreparationQuestionArrayForNextStepReplaceMedicine();
    } else {
      deleteNextStepReplaceMedicineFields();
    }

    const reducerType = {
      type: '[UPDATE]',
      payload: {
        followUpPrescriptionsIndex: changeMed ? 8 : 9,
      },
    };
    setCurrentPage(reducerType);
  };

  const getMedicationText = () => {
    return lastMedicationPreparations.length > 1 ? 'die Präparate' : 'das Präparat';
  };

  return (
    <div className={cx('Availability')}>
      <ProgressSteps>
        <ProgressStep step={1} title='Fragebogen' active />
        <ProgressStep step={2} title='Apotheke' active />
        <ProgressStep step={3} title='Lieferbarkeit' active />
        <ProgressStep step={4} title='Bestätigung' />
        <ProgressStep step={5} title='Zahlung' />
      </ProgressSteps>
      <Headline level={3}>Lieferbarkeit</Headline>
      <div className={cx('medication-wrapper')}>
        <p>Ihre aktuelle Medikation ist:</p>
        <div>
          {lastMedicationPreparations.map((item) => (
            <div key={item.id} className={cx('medication-name')}>
              {item.name} ({item.cultivar})
            </div>
          ))}
        </div>
      </div>
      <div className={cx('availability-question')}>
        <div className={cx('question')}>Möchten Sie diese Medikation fortsetzen oder ändern?</div>
        <div className={cx('choices')}>
          <div className={cx('radio-button-wrapper')}>
            <RadioButton
              extraClasses={cx('choice')}
              checked={prescriptionTemp?.medicationAvailable === 'yes'}
              value='yes'
              name='availability'
              id='availability-yes'
              onClick={() => {
                setPrescriptionTemp({
                  ...prescriptionTemp,
                  medicationAvailable: 'yes',
                });
              }}
            >
              Ja, ich möchte diese Medikation fortsetzen und habe die Lieferbarkeit überprüft.
            </RadioButton>
            <RadioButton
              extraClasses={cx('choice')}
              checked={prescriptionTemp?.medicationAvailable === 'changeMedication'}
              value='changeMedication'
              name='availability'
              id='availability-changeMedication'
              onClick={() => {
                setPrescriptionTemp({
                  ...prescriptionTemp,
                  medicationAvailable: 'changeMedication',
                });
              }}
            >
              Nein, ich möchte {getMedicationText()} ändern
            </RadioButton>
          </div>
        </div>
      </div>
      {prescriptionTemp?.medicationAvailable === 'no' && (
        <div className={cx('remark')}>
          <span className={'bold'}>Hinweis:</span> Sollte Ihre Medikation nicht lieferbar sein, kann es zu Verzögerungen
          kommen. Ihr Arzt muss möglicherweise ein Austauschpräparat festlegen.
        </div>
      )}
      <div className={cx('button-container')}>
        <Button secondary className={cx('button')} onClick={handleBackButton}>
          Zurück
        </Button>
        <Button className={cx('button')} disabled={isButtonDisabled} onClick={next}>
          Weiter
        </Button>
      </div>
    </div>
  );
}
